import "./App.css";
import Navigation from "./components/navigation/navigation";
import Footer from "./components/footer/footer";
import Contact from "./components/contact/contact";
import Portfolio from "./components/portfolio/portfolio";
import Service from "./components/service/service";
import Presentation from "./components/presentation/presentation";
import Accueil from "./components/accueil/accueil";
import Recommentation from "./components/recommentation/recommentation";
import Cursus from "./components/cursus/cursus";
import Experience from "./components/experience/experience";
import Skills from "./components/skills/skills";


function App() {
  return (
    <div className="app">
      <Navigation/>
      <Accueil/>
      <Presentation/>
      <Cursus/>
      <Experience/>
      <Skills/>
      <Service/>
      <Portfolio/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
