import React, { Component } from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "react-circular-progressbar/dist/styles.css";
import './cursus.css'

export default class Cursus extends Component{
  state = {}

  render() {
    return (
      <div id="cursus">
        <h1 className="section-title">Mon Cursus</h1>
          <hr className="colored-hr" />
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#000", color: "#fff" }}
              date="2014 - 2017"
              iconStyle={{ background: "#000", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">
                Baccalauréat STI2D
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Evry, FRANCE
              </h4>
              <p>
                Baccalauréat Sciences et Technologies de l'Industrie et du
                Développement Durable - option Systèmes d'Information et
                Numérique
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#000", color: "#fff" }}
              date="2017 - 2019"
              iconStyle={{ background: "#000", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">BTS SNIR</h3>
              <h4 className="vertical-timeline-element-subtitle">
                Corbeil-Essonnes, France
              </h4>
              <p>
                Brevet de Technicien Supérieur - Système numérique option
                informatique et réseau
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#000", color: "#fff" }}
              date="2019 - 2020"
              iconStyle={{ background: "#000", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">CPGE ATS</h3>
              <h4 className="vertical-timeline-element-subtitle">
                Corbeil-Essonnes, France
              </h4>
              <p>
                Classes Préparatoires aux Grandes Écoles - Adaptation Technicien
                Supérieur
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#000", color: "#fff" }}
              date="2022 - 2023"
              iconStyle={{ background: "#000", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">
                Échange Universitaire, Université de Sherbrooke
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Sherbrooke, CANADA
              </h4>
              <p>
                Echange universitaire à l'Université de Sherbrooke, dans le
                domaine de l'informatique et de la cybersecurité
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              id='last'
              contentStyle={{ background: "#fff", color: "#000" }}
              contentArrowStyle={{ borderRight: "7px solid  #000" }}
              date="2020 - 2023"
              iconStyle={{ background: "#000", color: "#fff" }}
            >
              <h3 className="vertical-timeline-element-title">
                Diplôme d'ingénieur Informatique à l'ENSIIE
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                Evry, FRANCE
              </h4>
              <p>
                Diplôme d'ingénieur Informatique à l'École Nationale Supérieure
                d'Informatique pour l'Industrie et l'Entreprise (ENSIIE) -
                Spécialisation en Génie logiciel et Sécurité
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
      </div>
    )
  }
}