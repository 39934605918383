import React, { Component } from "react";
import { Icon, Button } from "semantic-ui-react";
import "./portfolio.css";

export default class Portfolio extends Component {
  state = {
    project: [
      {
        title: "Ilili",
        image: "/images/projects/ilili/presentation.png",
        github: "https://github.com/LahoucineLapunaire/ilili",
        demo: "https://ilili-app.com/",
      },
      {
        title: "NearCard",
        image: "/images/projects/nearcard/presentation.png",
        github: "https://github.com/LahoucineLapunaire/nearcard",
        demo: "https://elaborate-stardust-9b6eeb.netlify.app/",
      },
    ],
  };

  render() {
    return (
      <div id="portfolio">
        <h1 className="section-title">Mon Portfolio</h1>
        <hr className="colored-hr" />
        <div id="project-section">
          {this.state.project.map((project) => (
            <Project
              key={project.title}
              title={project.title}
              description={project.description}
              image={project.image}
              github={project.github}
              demo={project.demo}
            />
          ))}
        </div>
      </div>
    );
  }
}

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    return (
      <div className="project" onClick={() => console.log("clicked")}>
        <div id="project-image">
          <img src={this.props.image} alt="project" />
        </div>
          <h2>{this.props.title}</h2>
          <div id="project-button">
          <Button animated="fade" onClick={() => window.open(this.props.github)} size={window.innerWidth > 780 ? "big" : "small"}>
      <Button.Content visible>Voir GitHub</Button.Content>
      <Button.Content hidden>
        <Icon name='github' />
      </Button.Content>
    </Button>
    <Button animated="fade" onClick={() => window.open(this.props.demo)} size={window.innerWidth > 780 ? "big" : "small"}>
      <Button.Content visible>Voir Demo</Button.Content>
      <Button.Content hidden>
        <Icon name='computer' />
      </Button.Content>
    </Button>
        </div>
      </div>
    );
  }
}
