import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "react-circular-progressbar/dist/styles.css";
import "./experience.css";

export default class Experience extends Component{
  state = {}

  render() {
    return (
        <div id="experience">
        <h1 className="section-title">Mes experiences professionnelles</h1>
        <hr className="colored-hr" />
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#000", color: "#fff" }}
            date="Mai 2021 - Aout 2021"
            iconStyle={{ background: "#000", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Developpeur Front-End
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              E-thik - Lisses, FRANCE
            </h4>
            <p>
              Stage en tant que developpeur Front-End. Le but de ma mission
              était de créer une interface pour un refrigirateur connécté avec
              React.js. Utilisation de Python(Django) pour le Back-end.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#000", color: "#fff" }}
            date="Mai 2022 - Aout 2022"
            iconStyle={{ background: "#000", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Analyste sécurité
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              AXA IM - La Defense, France
            </h4>
            <p>
              Stage en tant qu'analyste sécurité. Le but de la mission était
              d'étudier et de produire un dossier complet sur la sécurité de
              la blockchain avec création de framework d'analyse. La deuxieme
              mission était de créer une formation contre le physique pour les
              employés d'AXA IM.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#000", color: "#fff" }}
            date="Mai 2022 - Aout 2022"
            iconStyle={{ background: "#000", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Stage entreprenarial
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              IMT Starter - Evry, France
            </h4>
            <p>
              Stage entreprenarial au sein de l'incubateur IMT Starter.
              Decouverte et formation sur l'entreprenariat. Creation de
              plusieurs projets entreprenariaux.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            id='last'
            contentStyle={{ background: "#fff", color: "#000" }}
            contentArrowStyle={{ borderRight: "7px solid  #000" }}
            date="Janvier 2024 - Mars 2024"
            iconStyle={{ background: "#000", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Développeur Mobile
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              No Limits On Line - Suisse
            </h4>
            <p>
              Développeur Mobile Freelance chez No Limits On Line. Création
              de l'application mobile Relay Autobooker Pro avec Flutter et
              Firebase. L'application permet de rechercher et réserver automatiquement
              des livraisons sur le site de Amazon Relay
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    )
  }
}