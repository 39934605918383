import React, { Component } from "react";
import "./presentation.css";
import "react-circular-progressbar/dist/styles.css";
import { Icon } from "semantic-ui-react";



export default class Presentation extends Component {
  

  render() {
    return (
      <div id="presentation">
        <h1 className="section-title">Qui je suis ?</h1>
        <hr className="colored-hr" />
        <div id="presentation_generale">
          <img id="profile_picture" src="/images/profile.jpeg" />
          <div id="description">
            <div id="about-container">
              <div className="details-container">
                <Icon className="icon" name="rocket" size="large" />
                <h3>Experience</h3>
                <p>Développeur Mobile Freelance chez No Limits On Line</p>
              </div>
              <div className="details-container">
                <Icon className="icon" name="graduation cap"  size="large" />
                <h3>Diplôme</h3>
                <p>Diplôme d'ingénieur en Génie Logiciel et Sécurité de l'ENSIIE, Evry</p>
              </div>
            </div>
            <p>
              Bienvenue ! Je suis Lahoucine LAPUNAIRE, un développeur passionné
              spécialisé dans le développement mobile avec Flutter et Firebase.
              Mon parcours académique en génie logiciel et ma curiosité pour la
              blockchain et la cybersécurité alimentent ma passion pour la
              programmation et la créativité. Rejoignez-moi dans cette aventure
              excitante où la technologie rencontre la créativité !
            </p>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
