import React, { Component } from "react";
import { Button, Icon, Modal, Input } from "semantic-ui-react";
import RatingStars from 'react-rating-stars-component';
import "./recommendation.css";

class RecommendationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      author: "",
      title: "",
      comment: "",
      rating: 0,
    };
  }

  handleRatingChange = (e) => {
    var result = parseInt(e)
    console.log(e)
    this.setState({rating: result});
  };

  handleSubmit = () => {
    // Vous pouvez traiter les données du formulaire ici, par exemple, les envoyer à un serveur.
    console.log(this.state);
  };

  render() {
    return (
      <div id="recommendation-form">
        <h2>Ajouter une recommandation</h2>
        <h4>Ajoutez une recommandation en remplissant le formulaire ci-dessous</h4>
        <input id="input_nom" placeholder="Entrez votre nom" />
        <br />
        <br />
        <input id="input_poste" placeholder="Entrez votre poste" />
        <br />
        <br />
        {/* Utilisez le composant RatingStars */}
        <RatingStars
          count={5} // Nombre total d'étoiles
          onChange={this.handleRatingChange} // Gestionnaire de changement de note
          size={window.innerWidth < 768 ? 30 : 45} // Taille des étoiles
          color="black" // Couleur des étoiles remplies
          activeColor="#6C1B21" // Couleur des étoiles actives
          a11y={true}
        />
        <br />
        <br />
        <textarea  id="input_commentaire" rows={5} />
        <br />
        <br />
        <br />
        <Button
          size={window.innerWidth < 768 ? "tiny" : "medium"}
          circular
          style={{
            backgroundColor: '#6C1B21',
            color: 'white',
          }}
          animated="horizontal"
          id="modal-button"
          onClick={()=> this.handleSubmit()}
        >
          <Button.Content visible>Ajouter une recommandation</Button.Content>
          <Button.Content hidden>
            <Icon name="arrow right" />
          </Button.Content>
        </Button>
      </div>
    );
  }
}

class RecommendationItem extends React.Component {
  renderRatingStars = (note) => {
    const maxStars = 5;
    const filledStars = Math.min(note, maxStars);
    const emptyStars = maxStars - filledStars;

    const filledStarIcon = "★";
    const emptyStarIcon = "☆";

    return (
      <div className="rating">
        {[...Array(filledStars)].map((_, index) => (
          <span key={index} className="filled-star">
            {filledStarIcon}
          </span>
        ))}
        {[...Array(emptyStars)].map((_, index) => (
          <span key={index} className="empty-star">
            {emptyStarIcon}
          </span>
        ))}
      </div>
    );
  };

  componentDidMount() {
    // Set an interval to scroll the container every 3 seconds (adjust as needed)
    this.scrollInterval = setInterval(() => {
      const container = document.querySelector(".recommendation-container");
      if (container) {
        container.scrollLeft += 1; // Scroll by 1 pixel (adjust as needed)
      }
    }, 3000); // Scroll every 3 seconds
  }

  componentWillUnmount() {
    // Clear the interval when the component unmounts
    clearInterval(this.scrollInterval);
  }

  render() {
    const { auteur, poste, commentaire, note } = this.props;

    return (
      <div className="recommendation-item">
        <h1 className="auteur">{auteur}</h1>
        <h3 className="poste">{poste}</h3>
        {this.renderRatingStars(note)}
        <br />
        <p className="commentaire">{commentaire}</p>
      </div>
    );
  }
}

export default class Recommentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalState: false,
      recommendations: [
        {
          auteur: "John Doe",
          poste: "Musician",
          commentaire:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          note: 4,
        },
        {
          auteur: "John Doe",
          poste: "Musician",
          commentaire:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          note: 5,
        },
        {
          auteur: "John Doe",
          poste: "Musician",
          commentaire:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          note: 5,
        },
        {
          auteur: "John Doe",
          poste: "Musician",
          commentaire:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          note: 1,
        },
        {
          auteur: "John Doe",
          poste: "Musician",
          commentaire:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          note: 2,
        },
        {
          auteur: "John Doe",
          poste: "Musician",
          commentaire:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          note: 3,
        },
        {
          auteur: "John Doe",
          poste: "Musician",
          commentaire:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          note: 3,
        },
        {
          auteur: "John Doe",
          poste: "Musician",
          commentaire:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          note: 3,
        },
      ],
    };
    this.handleModalStateChange = this.handleModalStateChange.bind(this);
  }

  handleModalStateChange = (_newModalState) => {
    this.setState({
      modalState: _newModalState,
    });
  };

  render() {
    return (
      <div id="recommendation">
        <h1 className="section-title">Mes Recommendations</h1>
        <hr className="colored-hr" />
        <Modal
          id="modal-recommendation"
          onClose={() => {
            this.handleModalStateChange(false);
          }}
          onOpen={() => {
            this.handleModalStateChange(true);
          }}
          open={this.state.modalState}
          trigger={
            <Button
              size={window.innerWidth < 768 ? "mini" : "big"}
              circular // Pour créer un bouton rond
              style={{
                backgroundColor: "#6C1B21", // Couleur de fond
                color: "white", // Couleur des icônes
              }}
              animated="fade"
              className="menu-button"
            >
              <Button.Content visible>
                Ajouter une recommandation
              </Button.Content>
              <Button.Content hidden>
                <Icon name="plus" />
              </Button.Content>
            </Button>
          }
        >
          <RecommendationForm />
        </Modal>
        <br />
        <br />
        <div className="recommendation-container">
          {this.state.recommendations.map((recommendation, index) => (
            <RecommendationItem
              key={index}
              auteur={recommendation.auteur}
              poste={recommendation.poste}
              commentaire={recommendation.commentaire}
              note={recommendation.note}
            />
          ))}
        </div>
      </div>
    );
  }
}
