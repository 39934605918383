import React, { Component, useRef } from "react";
import "./contact.css";
import { Form, Button, Icon, Message } from "semantic-ui-react";
import emailjs from "@emailjs/browser";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_email: "",
      message: "",
      successMessage: "",
      errorMessage: "",
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    emailjs
      .send(
        "service_0bl2k44",
        "template_83v7jf9",
        {
          to_name: "Lahoucine",
          from_name: this.state.user_email,
          message: this.state.message,
        },
        "L5sgj7RU6LCB6ZAMe"
      )
      .then(
        (result) => {
          this.setState({ successMessage: 'Email sent successfully', errorMessage: '' });
          setTimeout(() => {
            this.setState({ successMessage: '' });
          }, 5000);
          console.log("L'email a été envoyé !");
        },
        (error) => {
          this.setState({ errorMessage: 'Email failed to send', successMessage: '' });
          setTimeout(() => {
            this.setState({ errorMessage: '' });
          }, 5000);
          console.error("Erreur : ", error.text);
        }
      );
  };

  render() {
    return (
      <div id="contact" className="contact-section">
        <h1 className="section-title">Me contacter</h1>
        <hr className="colored-hr" />
        {this.state.successMessage && <Message positive>{this.state.successMessage}</Message>}
        {this.state.errorMessage && <Message negative>{this.state.errorMessage}</Message>}

        <div className="contact-container">
          <div className="contact-form">
            <Form onSubmit={this.handleSubmit}>
              <Form.Input
                label="Email"
                name="user_email"
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
                required
              />
              <Form.TextArea
                label="Message"
                name="message"
                value={this.state.message}
                onChange={this.handleChange}
                required
              />
              <Button
                size="medium"
                circular
                style={{
                  backgroundColor: "#78c7d4",
                  color: "black",
                }}
                animated="horizontal"
                id="modal-button"
                onClick={this.handleChange}
              >
                <Button.Content visible>Envoyer</Button.Content>
                <Button.Content hidden>
                  <Icon name="send" />
                </Button.Content>
              </Button>
            </Form>
          </div>
          <div className="contact-info">
            <h2>Informations de contact</h2>
            <p>Email: lahoucineL.freelance@gmail.com</p>
            <div id="social_section">
              <div
                className="button-with-zoom"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/in/lahoucine-lapunaire-495016198/"
                  )
                }
              >
                <img src="/images/linkedin.png" alt="Image" />
              </div>
              <br />
              <div
                className="button-with-zoom"
                onClick={() =>
                  window.open("https://www.malt.fr/profile/lahoucinelapunaire")
                }
              >
                <img src="/images/malt.png" alt="Image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
