import React, { Component } from "react";
import "./skills.css";

class Skill extends Component {
  render() {
    return (
      <div className="skill">
        <img
          className="skill-picture"
          src={this.props.logo}
          alt={this.props.name}
        />
        <div className="skill-text">
          <h4>{this.props.name}</h4>
          <p>{this.props.level}</p>
        </div>
      </div>
    );
  }
}

export default class Skills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frontend: [
        {
          name: "React.Js",
          level: "Avancé",
          logo: "/images/skills/react.svg",
        },
        {
          name: "Javascript",
          level: "Avancé",
          logo: "/images/skills/js.svg",
        },
        { name: "HTML", level: "Avancé", logo: "/images/skills/html.svg" },
        { name: "CSS", level: "Avancé", logo: "/images/skills/css.svg" },
        {
          name: "Semantic UI",
          level: "Intermédiaire",
          logo: "/images/skills/semanticui.svg",
        },
      ],
      backend: [
        {
          name: "Firebase",
          level: "Avancé",
          logo: "/images/skills/firebase.svg",
        },
        {
          name: "Python",
          level: "Intermédiaire",
          logo: "/images/skills/python.svg",
        },
        {
          name: "Django",
          level: "Débutant",
          logo: "/images/skills/django.svg",
        },
        {
          name: "Golang",
          level: "Intermédiaire",
          logo: "/images/skills/golang.svg",
        },
        {
          name: "GIN",
          level: "Intermédiaire",
          logo: "/images/skills/gin.svg",
        },
        { name: "Node.Js", level: "Débutant", logo: "/images/skills/node.svg" },
        { name: "SQL", level: "Avancé", logo: "/images/skills/flutter.svg" },
        {
          name: "MySQL",
          level: "Débutant",
          logo: "/images/skills/mysql.svg",
        },
      ],
      mobile: [
        {
          name: "Flutter",
          level: "Avancé",
          logo: "/images/skills/flutter.svg",
        },
        {
          name: "Android Studio",
          level: "Débutant",
          logo: "/images/skills/androidstudio.svg",
        },
        {
          name: "Xcode",
          level: "Débutant",
          logo: "/images/skills/xcode.svg",
        },
        {
          name: "Google Play Store",
          level: "Intermédiaire",
          logo: "/images/skills/google.svg",
        },
        {
          name: "Apple App Store",
          level: "Intermédiaire",
          logo: "/images/skills/apple.svg",
        },
        { name: "Dart", level: "Avancé", logo: "/images/skills/dart.svg" },
      ],
      others: [
        {
          name: "Blockchain",
          level: "Intermédiaire",
          logo: "/images/skills/blockchain.svg",
        },
        {
          name: "Solidity",
          level: "Débutant",
          logo: "/images/skills/solidity.svg",
        },
        {
          name: "Pentesting",
          level: "Débutant",
          logo: "/images/skills/pentesting.svg",
        },
        {
          name: "Git",
          level: "Intermédiaire",
          logo: "/images/skills/git.svg",
        },

        {
          name: "Figma",
          level: "Débutant",
          logo: "/images/skills/figma.svg",
        },
        {
          name: "UX/UI",
          level: "Débutant",
          logo: "/images/skills/uxui.svg",
        },
        {
          name: "Netlify",
          level: "Intermédiaire",
          logo: "/images/skills/netlify.svg",
        },
      ],
    };
  }

  render() {
    return (
      <div id="skills">
        <h1 className="section-title">Mes compétences clés</h1>
        <hr className="colored-hr" />
        <div id="skills-container">
          <div className="skills-category">
            <h3>Developpement Front-End</h3>
            <div className="skills-list">
              {this.state.frontend.map((skill, index) => {
                return (
                  <Skill
                    key={index}
                    logo={skill.logo}
                    name={skill.name}
                    level={skill.level}
                  />
                );
              })}
            </div>
          </div>
          <div className="skills-category">
            <h3>Developpement Back-End</h3>
            <div className="skills-list">
              {this.state.backend.map((skill, index) => {
                return (
                  <Skill
                    key={index}
                    logo={skill.logo}
                    name={skill.name}
                    level={skill.level}
                  />
                );
              })}
            </div>
          </div>
          <div className="skills-category">
            <h3>Technologies Mobile</h3>
            <div className="skills-list">
              {this.state.mobile.map((skill, index) => {
                return (
                  <Skill
                    key={index}
                    logo={skill.logo}
                    name={skill.name}
                    level={skill.level}
                  />
                );
              })}
            </div>
          </div>
          <div className="skills-category">
            <h3>Outils et Autres Compétences </h3>
            <div className="skills-list">
              {this.state.others.map((skill, index) => {
                return (
                  <Skill
                    key={index}
                    logo={skill.logo}
                    name={skill.name}
                    level={skill.level}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
