import React, { Component } from "react";
import {
  Button,
  Icon,
  Segment,
  TransitionablePortal,
  Header,
} from "semantic-ui-react";
import "./navigation.css";

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false, // Pour suivre l'état du menu
      activeButton: "",
      animation: "scale",
      duration: 500,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  handleMenuClick = () => {
    // Inverse la valeur du state pour ouvrir/fermer le menu
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  componentDidMount() {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    const sections = [
      "accueil",
      "service",
      "portfolio",
      "contact",
      "presentation",
    ];

    let activeSection = "";
    for (const section of sections) {
      const element = document.getElementById(section);
      if (
        element.offsetTop <= scrollPosition &&
        element.offsetTop + element.offsetHeight > scrollPosition
      ) {
        activeSection = section;
        break;
      }
    }

    this.setState({ activeButton: activeSection });
  };

  handleButtonClick = (sectionId) => {
    // Manually trigger a scroll event with an offset of 100px
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = element.offsetTop + 1; // Add 100px offset
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
      this.handleMenuClick();
    }
  };

  render() {

    return (
      <div className="navigation">
        <Button
          id="button-open-menu"
          animated="fade"
          size={window.innerWidth > 780 ? "small" : "mini"}
          onClick={() => this.handleMenuClick()}
        >
          <Button.Content visible>
            <Icon name="sidebar" />
          </Button.Content>
          <Button.Content hidden>
            <Icon name="arrow left" />
          </Button.Content>
        </Button>
        <TransitionablePortal
          open={this.state.isMenuOpen}
          transition={{
            animation: this.state.animation,
            duration: this.state.duration,
          }}
        >
          <Segment id="portal-menu">
            <Header> 
              <Icon
                size={window.innerWidth > 780 ? "small" : "mini"}
                color="red"
                name="close"
                onClick={() => this.handleMenuClick()}/>
              Menu</Header>
            <div id="portal-button-section">
            <Button
            size="massive"
            animated="fade"
            style={{
              backgroundColor: 'transparent',  // Fond transparent
              color: 'black',  // Texte en noir
            }}
            onClick={() => this.handleButtonClick('accueil')}
          >
            <Button.Content visible>Accueil</Button.Content>
            <Button.Content hidden>
              <Icon name="home" />
            </Button.Content>
          </Button>
            <Button
            size="massive"
            animated="fade"
            style={{
              backgroundColor: 'transparent',  // Fond transparent
              color: 'black',  // Texte en noir
            }}
            onClick={() => this.handleButtonClick('service')}
          >
            <Button.Content visible>Services</Button.Content>
            <Button.Content hidden>
              <Icon name="dollar sign" />
            </Button.Content>
          </Button>
          <Button
            size="massive"
            animated="fade"
            className="header-button"
            style={{
              backgroundColor: 'transparent',  // Fond transparent
              color: 'black',  // Texte en noir
            }}
            onClick={() => this.handleButtonClick('portfolio')}
          >
            <Button.Content visible>Portfolio</Button.Content>
            <Button.Content hidden>
              <Icon name="briefcase" />
            </Button.Content>
          </Button>

          <Button
            size="massive"
            animated="fade"
            className="header-button"
            style={{
              backgroundColor: 'transparent',  // Fond transparent
              color: 'black',  // Texte en noir
            }}
            onClick={() => this.handleButtonClick('contact')}
          >
            <Button.Content visible>Contact</Button.Content>
            <Button.Content hidden>
              <Icon name="envelope" />
            </Button.Content>
          </Button>
            </div>
          </Segment>
        </TransitionablePortal>
      </div>
    );
  }
}
