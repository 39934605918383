import React, { Component } from 'react'
import {TypeAnimation} from 'react-type-animation'
import './accueil.css'

export default class Accueil extends Component{

  render() {
    return (
      <div id="accueil">
        <h1>Lahoucine LAPUNAIRE</h1>
        <h2>Developpeur Mobile Flutter - Android, iOS, Web</h2>
        <TypeAnimation
        id="type-animation"
  sequence={[
    // Same substring at the start will only be typed once, initially
    'Je developpe des applications \n pour les startups',
    1000,
    'Je developpe des applications \n sur Android, iOS et Web',
    1000,
    'Je developpe des applications \n avec Flutter',
    1000,
  ]}
  speed={50}
  style={{ fontSize: (window.innerWidth > 780 ? "2em" : "1.2em") , color: '#fff', fontFamily: 'Poppins'}}
  repeat={Infinity}
/>
      </div>
    )
  }
}