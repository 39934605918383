import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import "./service.css";

class ServiceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.side === "left") {
      return (
        <div id="left-card">
          <div>
            <Icon
              circular
              size={window.innerWidth > 768 ? "big" : "small"}
              className="card-icon"
              name={this.props.icon}
            />
          </div>
          <div>
            <h3>{this.props.title}</h3>
            <p>{this.props.description}</p>
          </div>
        </div>
      );
    } else {
      return (
        <div id="right-card">
          <div>
            <Icon
              circular
              size={window.innerWidth > 768 ? "big" : "small"}
              className="card-icon"
              name={this.props.icon}
            />
          </div>
          <div>
            <h3>{this.props.title}</h3>
            <p>{this.props.description}</p>
          </div>
        </div>
      );
    }
  }
}

export default class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          icon: "mobile alternate",
          title: "Développement d'Applications Mobiles",
          description:
            "Création d'applications mobiles sur mesure pour Android, iOS et Web.",
          valueProposition:
            "Offrez une expérience utilisateur exceptionnelle sur toutes les plateformes.",
        },
        {
          icon: "desktop",
          title: "Développement d'Applications Web",
          description:
            "Conception et développement de solutions web réactives et performantes.",
          valueProposition:
            "Transformez vos idées en applications web modernes et conviviales.",
        },
        {
          icon: "code branch",
          title: "Intégration Continue",
          description:
            "Mise en place de pipelines CI/CD pour un déploiement continu.",
          valueProposition:
            "Livraison rapide et fiable de nouvelles fonctionnalités et mises à jour.",
        },
        {
          icon: "code",
          title: "Optimisation de Performance",
          description:
            "Analyse et amélioration des performances des applications existantes.",
          valueProposition:
            "Accélérez vos applications pour une expérience utilisateur fluide.",
        },
        {
          icon: "cloud upload",
          title: "Publication d'Applications",
          description:
            "Mise en ligne et publication d'applications sur différentes plateformes.",
          valueProposition:
            "Rendez vos applications accessibles à un large public.",
        },
        {
          icon: "edit",
          title: "Ajout de Fonctionnalités",
          description:
            "Intégration de nouvelles fonctionnalités et améliorations sur des applications existantes.",
          valueProposition:
            "Augmentez la valeur de vos applications avec des fonctionnalités innovantes.",
        },
        {
          icon: "wrench",
          title: "Maintenance d'Applications",
          description:
            "Assistance et résolution de problèmes pour maintenir vos applications en parfait état de fonctionnement.",
          valueProposition:
            "Assurez la stabilité et la performance continues de vos applications.",
        },
        {
          icon: "book",
          title: "Consultation en Technologie",
          description:
            "Conseils techniques et expertise en développement pour orienter vos projets.",
          valueProposition:
            "Profitez de conseils d'experts pour des décisions technologiques éclairées.",
        },
      ],
    };
  }

  render() {
    if (window.innerWidth > 768) {
      return (
        <div id="service">
          <h1 className="section-title">Mes services</h1>
          <hr className="colored-hr" />
          <div className="service-container">
            <div className="service-list">
              {this.state.services.map((service, index) => {
                if (index < 4)
                  return (
                    <ServiceCard
                      side="left"
                      icon={service.icon}
                      title={service.title}
                      description={service.description}
                      value={service.valueProposition}
                    />
                  );
              })}
            </div>
            <div id="phone-section">
              <img src="/images/phone2.png" />
            </div>
            <div className="service-list">
              {this.state.services.map((service, index) => {
                if (index >= 4)
                  return (
                    <ServiceCard
                      side="right"
                      icon={service.icon}
                      title={service.title}
                      description={service.description}
                      value={service.valueProposition}
                    />
                  );
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id="service">
          <h1 className="section-title">Mes services</h1>
          <hr className="colored-hr" />
          <div className="service-container">
            <div id="phone-section">
              <img src="/images/phone1.png" />
            </div>
            <div id="services-list">
              {this.state.services.map((service, index) => {
                return (
                  <ServiceCard
                    side="right"
                    icon={service.icon}
                    title={service.title}
                    description={service.description}
                    value={service.valueProposition}
                  />
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }
}
