import React, { Component } from "react";
import "./footer.css";
import { Button, Icon } from "semantic-ui-react";

export default class Footer extends Component {
  state = {};

  handleButtonClick = (sectionId) => {
    console.log("ininin");
    // Manually trigger a scroll event with an offset of 100px
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = element.offsetTop + 1; // Add 100px offset
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  render() {
    return (
      <footer>
        <div>
          <div className="button_section">
            <Button
              animated="vertical"
              id="to_top_button"
              onClick={() => this.handleButtonClick("accueil")}
            >
              <Button.Content visible>
                <Icon name="arrow up " />
              </Button.Content>
              <Button.Content hidden>
                <Icon name="home " />
              </Button.Content>
            </Button>
            <div id="social_section">
              <div
                className="button-with-zoom"
                onClick={() => window.open("https://www.linkedin.com/in/lahoucine-lapunaire-495016198/")}
              >
                <img src="/images/linkedin.png" alt="Image" />
              </div>
              <br />
              <div className="button-with-zoom" onClick={() => window.open("https://www.malt.fr/profile/lahoucinelapunaire")}>
                <img src="/images/malt.png" alt="Image" />
              </div>
            </div>
          </div>
          <br />
          <div>
            <p>
              © 2023 Lahoucine LAPUNAIRE - Site codé en React.js et en Node.js
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
